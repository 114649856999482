<template>
  <div class="contact">
    <div class="contact-box">
      <span class="text-title">
        CONTACT INFORMATION
      </span>

      <div class="secretariats">
        <div class="secre-title">Conference Secretariat</div>
        <div v-for="secretariat in secretariats" class="secre-info">
          <div class="secre-name">{{secretariat.name}}</div>
          <div>{{secretariat.affiliation}}</div>
          <div>{{secretariat.tele}}</div>
          <div>{{secretariat.mail}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data(){
    return{
      secretariats:[
        {
          name:'Wei Jiang',
          affiliation:'School of Electrical Engineering, Southeast University',
          tele:'Tel: (+86) 13813860619',
          mail:'',
        },
        {
          name:'Hui Yang',
          affiliation:'School of Electrical Engineering, Southeast University',
          tele:'Tel: (+86) 15251867159',
          mail:'E-mail: ispec2021@csee.org.cn',
        },
        {
          name:'Yunyang Wang',
          affiliation:'Chinese Society for Electrical Engineering',
          tele:'Tel: 010-63415741，(+86)18511972197',
          mail:'E-mail: gjb@csee.org.cn',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.contact{
  background-image: url("../assets/images/contact/bcg.png");
  background-size: 90% 100%;
  height: 1160px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-box{
    background-color: #fff;
    opacity: 0.8;
    padding: 30px;

    .text-title{
    font-family: "Microsoft Yahei";
    font-size: 48px;
    color: #11A3CA;
    margin-top: 10px;
    font: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

  .secretariats{
    text-align: center;
    font-size: 20px;
    font-family: PingFang;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    .secre-title{
      margin: 30px 0;
    }

    .secre-info{
      margin: 30px 0;

      .secre-name{
        font-weight:  bold;
      }
    }

  }
}
</style>
